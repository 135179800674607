body {
  width:100%;
  margin-bottom: 10em;
}

header {
  width:100vw;
  position: sticky;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.container {
  max-width:100vw;
}

.whatbullets {
  width:80vw;
}

.carouseltext {
  color: white;
            -webkit-text-stroke-width: 2px;
            -webkit-text-stroke-color: black;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.carouselimg {
  object-fit: fill;
  width: 65vw;
  height: 45vh;
}

.mobilecarousel {
  object-fit: fill;
  width: 80vw;
  height: 40vh;
}

.whatcontainer {
   background-color: #2bd309;
  background-image: url("./images/whatbg.png");

  width: 100vw !important;
  object-fit: contain;
}

.whattext {
  text-align: justify;
  text-justify: inter-word;
}

.infographicmobile {
  width:90vw;
}
.infographicdesktop {
  width:30vw;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.desktop {
  display: none;
}
.cardheight {
  height: 35em;
}
.footercarousel {
  background-image: url("./images/whatbg.png");
  background-color: #2bd309;
  background-size: auto;
  height: 10vh;
  width: 100vw;
  position: fixed;
  bottom: 0;
  left:0;
}
.footerlogolt {
  width: 10em;
  position: absolute;
  top:2em;
  left:2em;

}
.footerlogort {
  width: 10em;
  position: absolute;
  top:2em;
  right:2em;

}
.footercarouselmobile {
  background-image: url("./images/whatbg.png");
  background-color: #2bd309;
  background-size: auto;
  height: 10vh;
  width: 100vw;
  position: fixed;
  bottom: 0;
  left:0;
}
.mobilefooterlogolt {
  width: 5em;
  position: absolute;
  top:1.5em;
  left:0.5em;

}

.mobilefooterlogort {
  width: 5em;
  position: absolute;
  top:1.5em;
  right:0.5em;

}
.mosqlogoheaddesktop {
  width: 4em;

}
.mosqlogoheadmobile {
  width: 2em;

}

.headbg {
  background-color: #2bd309;
  background-image: url("./images/whatbg.png");
  height:10vh;
  width: 100vw !important;
}
.puff-out-hor {
	-webkit-animation: puff-out-hor 2s linear 2s infinite both;
	        animation: puff-out-hor 2s linear 2s infinite both;
}


@media only screen and (min-width: 1200px) {
  .mobile {
      display: none;
  }

  .desktop {
      display: inline;
  }

  .card-container {
      margin-left: 2px;
      margin-right: 2px;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2022-5-25 11:57:15
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation puff-out-hor
 * ----------------------------------------
 */
 @-webkit-keyframes puff-out-hor {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1.5);
            transform: scaleX(1.5);
    -webkit-filter: blur(4px);
            filter: blur(4px);
    opacity: 0;
  }
}
@keyframes puff-out-hor {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1.5);
            transform: scaleX(1.5);
    -webkit-filter: blur(4px);
            filter: blur(4px);
    opacity: 0;
  }
}


