
.logo {
    max-width: 30vw;
}

@media only screen and (min-width: 1200px) {
    .logo {
        max-width: 10vw;
    }

}